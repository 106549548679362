
<template>
  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <!-- <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
      <el-breadcrumb-item>新增BUG</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="tabName" @tab-click="handleClick">
        <el-tab-pane label="CRM环境" name="first">
          <search_component
            :key="timer"
            :itemList="searchList"
            :formData="searchData"
          >
          </search_component>
          <div class="my_boox">
            <el-table
              :data="env_list"
              style="width: 100%"
              stripe
              height="700px"
              v-loading="loading"
              element-loading-background="rgba(0, 0, 0, 0.5)"
              element-loading-text="环境加载中..."
              >>
              <el-table-column label="序号" width="60">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="环境名称" width="140">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <div slot="reference" class="name-wrapper">
                      <a :href="scope.row.url" target="_blank"
                        ><el-tag size="medium">{{
                          scope.row.env_name
                        }}</el-tag></a
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="便捷导航" width="180">
                <template slot-scope="scope">
                  <a
                    :href="scope.row.admin_url"
                    style="margin-right: 8px"
                    target="_blank"
                    ><el-tag size="medium" type="warning">admin</el-tag></a
                  >

                  <a :href="scope.row.sws_url" target="_blank"
                    ><el-tag
                      size="medium"
                      style="margin-right: 8px"
                      type="warning"
                      >sws</el-tag
                    ></a
                  >
                  <a :href="scope.row.cp_url" target="_blank"
                    ><el-tag size="medium" type="warning">cp</el-tag></a
                  >
                </template>
              </el-table-column>
              <el-table-column label="关联需求" width="100">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.issue"
                    clearable
                    placeholder="请选择"
                    @change="add_issue($event, scope.row.env_name, 'crm')"
                  >
                    <el-option
                      v-for="item in myIssueList"
                      :key="item.key"
                      :label="item.key + item.title"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="admin_be" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('admin_be')">
                    <el-tag
                      v-if="scope.row.serves_dict.admin_be != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.admin_be }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>

                  <!-- <el-tag v-if="scope.row.serves_list.includes('admin_be')"
                      type="danger">已占用({{ scope.row.serves_dict.admin_be }})</el-tag>
              <el-tag v-else
                      type="success">未占用</el-tag> -->
                </template>
              </el-table-column>
              <!-- <el-table-column label="admin_portal"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('admin_portal')">
                <el-tag v-if="scope.row.serves_dict.admin_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.admin_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column> -->

              <el-table-column label="sws_be" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('sws_be')">
                    <el-tag
                      v-if="scope.row.serves_dict.sws_be != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.sws_be }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="sws_portal" width="110">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('sws_portal')">
                    <el-tag
                      v-if="scope.row.serves_dict.sws_portal != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.sws_portal }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="ib_web" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('ib_web')">
                    <el-tag
                      v-if="scope.row.serves_dict.ib_web != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.ib_web }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="client_portal" width="110">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('client_portal')">
                    <el-tag
                      v-if="scope.row.serves_dict.client_portal != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.client_portal }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="ib_portal" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('ib_portal')">
                    <el-tag
                      v-if="scope.row.serves_dict.ib_portal != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.ib_portal }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="app_service" width="110">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('app_service')">
                    <el-tag
                      v-if="scope.row.serves_dict.app_service != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.app_service }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="open_api" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('open_api')">
                    <el-tag
                      v-if="scope.row.serves_dict.open_api != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.open_api }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="au_cron" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('au_cron')">
                    <el-tag
                      v-if="scope.row.serves_dict.au_cron != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.au_cron }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>

              <el-table-column label="payment" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('payment_cron')">
                    <el-tag
                      v-if="scope.row.serves_dict.payment_cron != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.payment_cron }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="commission" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('commission_cron')">
                    <el-tag
                      v-if="scope.row.serves_dict.commission_cron != null"
                      type="danger"
                      >已占用({{
                        scope.row.serves_dict.commission_cron
                      }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
              <el-table-column label="liquibase" width="100">
                <template slot-scope="scope">
                  <div v-if="scope.row.serves_list.includes('liquibase')">
                    <el-tag
                      v-if="scope.row.serves_dict.liquibase != null"
                      type="danger"
                      >已占用({{ scope.row.serves_dict.liquibase }})</el-tag
                    >
                    <el-tag v-else type="danger">已占用</el-tag>
                  </div>
                  <div v-else><el-tag type="success">未占用</el-tag></div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="移动端环境" name="second">
          <search_component
            :key="timer"
            :itemList="searchAppList"
            :formData="appData"
          >
          </search_component>
       
            <el-table
              :data="app_env_list"
              style="width: 100%"
              stripe
              height="700px">
              <el-table-column label="序号" width="60">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="环境名称" width="140">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <div slot="reference" class="name-wrapper">
                      <a :href="scope.row.url" target="_blank"
                        ><el-tag size="medium">{{
                          scope.row.env_name
                        }}</el-tag></a
                      >
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <!-- <el-table-column label="便捷导航"
                           width="180">
            <template slot-scope="scope">

              <a :href="scope.row.admin_url"
                 style="margin-right: 8px;"
                 target="_blank"><el-tag size="medium"
                        type="warning">admin</el-tag></a>

              <a :href="scope.row.sws_url"
                 target="_blank"><el-tag size="medium"
                        style="margin-right: 8px;"
                        type="warning">sws</el-tag></a>
              <a :href="scope.row.cp_url"
                 target="_blank"><el-tag size="medium"
                        type="warning">cp</el-tag></a>
            </template>
          </el-table-column> -->
              <el-table-column label="关联需求" width="200">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.issue"
                    clearable
                    placeholder="请选择"
                    @change="add_issue($event, scope.row.env_name, 'app')"
                  >
                    <el-option
                      v-for="item in myIssueList"
                      :key="item.key"
                      :label="item.key + item.title"
                      :value="item.key"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="是否占用" width="140">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <div slot="reference" class="name-wrapper">
         
                      <el-tag size="medium" type="danger" v-if="scope.row.is_use == '1'">已占用</el-tag>
                      <el-tag size="medium" type="success" v-else>未占用</el-tag>
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column label="remark" width="300">
                <template slot-scope="scope">
                  <el-popover trigger="hover" placement="top">
                    <div slot="reference" class="name-wrapper">
         
                      <el-tag size="medium" type="danger" v-if="scope.row.is_use == '1'">{{ scope.row.remark }}</el-tag>
         
                    </div>
                  </el-popover>
                </template>
              </el-table-column>
              <!-- <el-table-column label="admin_be"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('admin_be')">
                <el-tag v-if="scope.row.serves_dict.admin_be != null"
                        type="danger">已占用({{ scope.row.serves_dict.admin_be }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>

            </template>
          </el-table-column>
    
          <el-table-column label="sws_be"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('sws_be')">
                <el-tag v-if="scope.row.serves_dict.sws_be != null"
                        type="danger">已占用({{ scope.row.serves_dict.sws_be }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>

              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="sws_portal"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('sws_portal')">
                <el-tag v-if="scope.row.serves_dict.sws_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.sws_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="ib_web"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('ib_web')">
                <el-tag v-if="scope.row.serves_dict.ib_web != null"
                        type="danger">已占用({{ scope.row.serves_dict.ib_web }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="client_portal"
                           width="110">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('client_portal')">
                <el-tag v-if="scope.row.serves_dict.client_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.client_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="ib_portal"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('ib_portal')">
                <el-tag v-if="scope.row.serves_dict.ib_portal != null"
                        type="danger">已占用({{ scope.row.serves_dict.ib_portal }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="app_service"
                           width="110">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('app_service')">
                <el-tag v-if="scope.row.serves_dict.app_service != null"
                        type="danger">已占用({{ scope.row.serves_dict.app_service }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="open_api"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('open_api')">
                <el-tag v-if="scope.row.serves_dict.open_api != null"
                        type="danger">已占用({{ scope.row.serves_dict.open_api }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="au_cron"
                           width="100">
            <template slot-scope="scope">

              <div v-if="scope.row.serves_list.includes('au_cron')">
                <el-tag v-if="scope.row.serves_dict.au_cron != null"
                        type="danger">已占用({{ scope.row.serves_dict.au_cron }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>

          <el-table-column label="payment"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('payment_cron')">
                <el-tag v-if="scope.row.serves_dict.payment_cron != null"
                        type="danger">已占用({{ scope.row.serves_dict.payment_cron }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="commission"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('commission_cron')">
                <el-tag v-if="scope.row.serves_dict.commission_cron != null"
                        type="danger">已占用({{ scope.row.serves_dict.commission_cron }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column>
          <el-table-column label="liquibase"
                           width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.serves_list.includes('liquibase')">
                <el-tag v-if="scope.row.serves_dict.liquibase != null"
                        type="danger">已占用({{ scope.row.serves_dict.liquibase }})</el-tag>
                <el-tag v-else
                        type="danger">已占用</el-tag>
              </div>
              <div v-else> <el-tag type="success">未占用</el-tag></div>
            </template>
          </el-table-column> -->
            </el-table>

        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card> </el-card>
  </div>
</template>

<script>
import search_component from '../common/Serach.vue';
export default {
  components: {
    search_component,
  },
  data() {
    return {
      app_env_list: [],
      tabName: 'first',
      myIssueList: [],
      loading: false,
      issue_value: '',
      timer: '',
      add_remark_form: {},
      env_list: [],
      searchData: {
        env_name: '',
        serves_list: [],
      },
      appData: {
        env_name: 'AU',
        serves_list: [],
      },
      resetData: {
        env_name: '',
        serves_list: [],
      },
      searchList: [
        {
          label: '环境名称',
          prop: 'env_name',
          type: 'select',
          // multiple: true,
          options: [
            // bug类型
            {
              label: 'Aardwolf',
              value: 'Aardwolf',
            },
            {
              label: 'Alpaca',
              value: 'Alpaca',
            },
            {
              label: 'Beaver',
              value: 'Beaver',
            },
            {
              label: 'Blowfish',
              value: 'Blowfish',
            },
            {
              label: 'Buffalo',
              value: 'Buffalo',
            },
            {
              label: 'Butterfly',
              value: 'Butterfly',
            },
            {
              label: 'Camel',
              value: 'Camel',
            },
            {
              label: 'Carrot',
              value: 'Carrot',
            },
            {
              label: 'Cauliflower',
              value: 'Cauliflower',
            },
            {
              label: 'Deer',
              value: 'Deer',
            },
            {
              label: 'Elephant',
              value: 'Elephant',
            },
            {
              label: 'Goat',
              value: 'Goat',
            },
            {
              label: 'Jaguar',
              value: 'Jaguar',
            },
            {
              label: 'Leopard',
              value: 'Leopard',
            },
            {
              label: 'Monkey',
              value: 'Monkey',
            },
            {
              label: 'Pumpkin',
              value: 'Pumpkin',
            },
            {
              label: 'Sheep',
              value: 'Sheep',
            },
            {
              label: 'Squirrel',
              value: 'Squirrel',
            },
            {
              label: 'Tiger',
              value: 'Tiger',
            },
            {
              label: 'Whale',
              value: 'Whale',
            },
            {
              label: 'Core-marketo-01',
              value: 'Core-marketo-01',
            },
            {
              label: 'Core-marketo-02',
              value: 'Core-marketo-02',
            },
            {
              label: 'Core-marketo-03',
              value: 'Core-marketo-03',
            },
          ],
        },

        {
          label: '服务名称：',
          prop: 'serves_list',
          type: 'select',
          multiple: true,
          // multiple: true,
          options: [
            // bug类型
            {
              label: 'admin_be',
              value: 'admin_be',
            },
            // {
            //   label: 'admin_portal',
            //   value: 'admin_portal'
            // },
            {
              label: 'sws_be',
              value: 'sws_be',
            },
            {
              label: 'sws_portal',
              value: 'sws_portal',
            },
            {
              label: 'ib_web',
              value: 'ib_web',
            },
            {
              label: 'client_portal',
              value: 'client_portal',
            },
            {
              label: 'ib_portal',
              value: 'ib_portal',
            },
            {
              label: 'app_service',
              value: 'app_service',
            },
            {
              label: 'open_api',
              value: 'open_api',
            },
            {
              label: 'au_cron',
              value: 'au_cron',
            },
            {
              label: 'liquibase',
              value: 'liquibase',
            },
          ],
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.serrch_env(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
            // { type: 'success', label: '获取最新数据', size: 'mini', icon: 'el-icon-setting', handle: row => this.downloadEvn() },
            // { type: 'success', label: '测试勿动', size: 'mini', icon: 'el-icon-setting', handle: row => this.test_test() },
            // { type: 'primary', label: '会议预约', size: 'mini', icon: 'el-icon-plus', handle: row => this.openDialog('add') }
          ],
        },
      ],
      searchAppList: [
        {
          label: '品牌',
          prop: 'env_name',
          type: 'select',
          change: this.select_brand,
          // multiple: true,
          options: [
            // bug类型
            {
              label: 'AU',
              value: 'AU',
            },
            {
              label: 'VT',
              value: 'VT',
            },
            {
              label: 'PU',
              value: 'PU',
            },
            {
              label: 'STAR',
              value: 'STAR',
            },
            {
              label: 'VJP',
              value: 'VJP',
            },
            {
              label: 'MO',
              value: 'MO',
            },
            {
              label: 'UM',
              value: 'UM',
            },
          ],
        },

        {
          label: '服务名称：',
          prop: 'serves_list',
          type: 'select',
          multiple: true,
          // multiple: true,
          options: [
            {
              label: 'AU1',
              value: 'AU1',
            },
            {
              label: 'AU2',
              value: 'AU2',
            },
            {
              label: 'AU3',
              value: 'AU3',
            },
            {
              label: 'AU4',
              value: 'AU4',
            },
            {
              label: 'AU5',
              value: 'AU5',
            },
            {
              label: 'AU6',
              value: 'AU6',
            },
            {
              label: 'CPS01',
              value: 'CPS01',
            },
          ],
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.app_service_list(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
            // { type: 'success', label: '获取最新数据', size: 'mini', icon: 'el-icon-setting', handle: row => this.downloadEvn() },
            // { type: 'success', label: '测试勿动', size: 'mini', icon: 'el-icon-setting', handle: row => this.test_test() },
            // { type: 'primary', label: '会议预约', size: 'mini', icon: 'el-icon-plus', handle: row => this.openDialog('add') }
          ],
        },
      ],
    };
  },

  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');

    // this.serrch_env()
  },
  mounted() {
    this.serrch_env();
  },
  methods: {
    async downloadEvn() {
      this.loading = true;
      const { data: res } = await this.$http.get(
        'py/server/project/jira/downloadEnv'
      );
      this.loading = false;
      if (res.success == true) {
        this.serrch_env();
      } else {
        this.$message.error(res.msg);
      }
    },
    async serrch_env(searchData) {
      const { data: res } = await this.$http.post(
        'py/server/project/jira/getEnvList',
        this.searchData
      );

      if (res.success == true) {
        this.env_list = res.data;
        this.getMyIssue();
        this.$message.success('环境查询成功');
      } else {
        this.$message.error(res.msg);
      }
    },

    search() {
      this.serrch_env(this.searchData);
    },
    reset() {
      this.searchData = {
        env_name: '',
        serves_list: [],
      };
      this.timer = new Date().getTime();
      this.serrch_env(this.searchData);
    },
    async getMyIssue() {
      const { data: res } = await this.$http.get(
        'py/server/project/jira/getMyIssue'
      );
      if (res.success) {
        this.myIssueList = res.data;
        console.log(this.myIssueList);
        // this.$message.success("需求获取成功")
      } else {
        this.$message.error(res.msg);
      }
    },
    async add_issue(issue_key, env_name, request_type) {
      this.add_remark_form.issue_key = issue_key;
      this.add_remark_form.env_name = env_name;
      const { data: res } = await this.$http.post(
        'py/server/project/jira/addIssueRemark',
        this.add_remark_form
      );
      if (res.success) {
        this.$message.success('环境关联成功');
        this.add_remark_form = {};
        if(request_type == 'crm'){
          
        }else{
          this.app_service_list();
        }
        
      } else {
        this.$message.error(res.msg);
        this.add_remark_form = {};
      }
    },
    select_brand() {
      if (this.appData.env_name == 'AU') {
        this.searchAppList[1].options = [
          {
            label: 'AU1',
            value: 'AU1',
          },
          {
            label: 'AU2',
            value: 'AU2',
          },
          {
            label: 'AU3',
            value: 'AU3',
          },
          {
            label: 'AU4',
            value: 'AU4',
          },
          {
            label: 'AU5',
            value: 'AU5',
          },
          {
            label: 'AU6',
            value: 'AU6',
          },
          {
            label: 'CPS01',
            value: 'CPS01',
          },
        ];
      }
      if (this.appData.env_name == 'VT') {
        this.searchAppList[1].options = [
          {
            label: 'VT1',
            value: 'VT1',
          },
          {
            label: 'VT2',
            value: 'VT2',
          },
          {
            label: 'VT3',
            value: 'VT3',
          },
          {
            label: 'VT4',
            value: 'VT4',
          },
        ];
      }
      if (this.appData.env_name == 'PU') {
        this.searchAppList[1].options = [
          {
            label: 'PU1',
            value: 'PU1',
          },
          {
            label: 'PU2',
            value: 'PU2',
          },
          {
            label: 'PU3',
            value: 'PU3',
          },
          {
            label: 'PU4',
            value: 'PU4',
          },
        ];
      }
      if (this.appData.env_name == 'STAR') {
        this.searchAppList[1].options = [
          {
            label: 'STAR1',
            value: 'STAR1',
          },
          {
            label: 'STAR2',
            value: 'STAR2',
          },
          {
            label: 'STAR3',
            value: 'STAR3',
          },
        ];
      }
      if (this.appData.env_name == 'VJP') {
        this.searchAppList[1].options = [
          {
            label: 'VJP1',
            value: 'VJP1',
          },
          {
            label: 'VJP2',
            value: 'VJP2',
          },
          {
            label: 'VJP3',
            value: 'VJP3',
          },
        ];
      }
      if (this.appData.env_name == 'UM') {
        this.searchAppList[1].options = [
          {
            label: 'UM1',
            value: 'UM1',
          },
          {
            label: 'UM',
            value: 'UM2',
          },
          {
            label: 'UM3',
            value: 'UM3',
          },
        ];
      }
      if (this.appData.env_name == 'MO') {
        this.searchAppList[1].options = [
          {
            label: 'MO1',
            value: 'MO1',
          },
        ];
      }
    },
    async app_service_list() {
      const { data: res } = await this.$http.post(
        'py/server/project/jira/getAppEnv',
        this.appData
      );
      if (res.success) {
        this.app_env_list = res.data;
        this.getMyIssue();
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClick(tab){
      if(tab.name == 'first'){
        this.serrch_env()
      }else{
        this.app_service_list()
      }

    }
  },
};
</script>


<style>
</style>